import { useMutation } from '@apollo/client';
import { Favorite, Search } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  Card,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Chip,
  Box,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { AutocompleteElement, FormContainer } from 'react-hook-form-mui';
import { FrequencyFilters, InsightType } from '../../__generated__/graphql';
import { GET_CURRENT_USER, GET_INSIGHTS } from '../../graphql/queries';
import { UPDATE_BRAND_INSIGHTS_TOPICS } from '../../graphql/mutations';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import TopicSelectorModal from './TopicSelectorModal'; // Import the modal from the second component
import StarIcon from '@mui/icons-material/Star'; // Add star icon for preferred

export interface InsightsFiltersData {
  topics: string[];
  authors: string[];
  favorites: boolean;
  frequency: FrequencyFilters;
  insightTypes: InsightType[];
}

export const InsightsFilters = ({
  filters,
  onFiltersChange,
  dateFilters,
}: {
  filters: InsightsFiltersData;
  onFiltersChange: (filters: InsightsFiltersData) => void;
  dateFilters: boolean;
}) => {
  const { t } = useTranslation();
  const [anchorModalElem, setAnchorModalElem] = useState<HTMLButtonElement | null>(null);
  const [topicModalOpen, setTopicModalOpen] = useState<boolean>(false); // State for the modal
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for the publication date menu
  const [selectedFrequency, setSelectedFrequency] = useState<FrequencyFilters | null>(
    FrequencyFilters.Month,
  );
  const { user } = useContext(AuthContext);

  const [updateUserInsights] = useMutation(UPDATE_BRAND_INSIGHTS_TOPICS, {
    refetchQueries: [GET_CURRENT_USER],
  });

  // Updated function to handle both topics and authors
  const handleUpdateSearch = (values: string[]) => {
    const uniqueValues = Array.from(new Set(values));

    // Clear authors and update topics
    onFiltersChange({
      ...filters,
      topics: uniqueValues, // Add topics and display them in the search bar
    });

    updateUserInsights({
      variables: {
        brandId: user?.brandSelectedId!,
        topics: uniqueValues,
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_INSIGHTS],
    });
  };

  const handleSwitchToLinkedIn = () => {
    onFiltersChange({
      ...filters,
      insightTypes: [InsightType.Linkedin],
      topics: [], // Clear topics when switching to LinkedIn
    });
  };

  const handleSwitchToNews = () => {
    onFiltersChange({
      ...filters,
      insightTypes: [InsightType.News],
      authors: [], // Clear authors when switching to News
    });
  };

  const handleTopicModalClick = (event: any) => {
    setAnchorModalElem(event.currentTarget);

    if (filters.favorites || filters.insightTypes.includes(InsightType.Linkedin)) {
      return;
    }

    setTopicModalOpen(true);
  };

  const handleDateButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateSelect = (period: FrequencyFilters) => {
    onFiltersChange({
      ...filters,
      frequency: period,
    });
    setSelectedFrequency(period);
    setAnchorEl(null); // Close the menu after selection
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        padding: '17px 25px',
        width: '100%',
        borderRadius: '8px',
        marginBottom: '1rem',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between" // Spread items across the row
        alignItems="center" // Align vertically in the center
        width="100%"
        mb={2} // Margin to space between rows
      >
        {/* Left side: LinkedIn and News buttons */}
        <Stack direction="row" spacing={1}>
          <Button
            variant={
              filters.insightTypes.includes(InsightType.Linkedin)
                ? 'contained'
                : 'outlined'
            }
            onClick={handleSwitchToLinkedIn}
          >
            LinkedIn
          </Button>

          <Button
            variant={
              filters.insightTypes.includes(InsightType.News) ? 'contained' : 'outlined'
            }
            onClick={handleSwitchToNews}
          >
            {t('News')}
          </Button>
        </Stack>

        {/* Right side: Search field and Favorite button */}
        <Stack direction="row" spacing={1} alignItems="center">
          <FormContainer values={{ topics: filters.topics }}>
            <AutocompleteElement
              name="topics"
              data-testid="topics-input"
              multiple
              options={[]}
              textFieldProps={{
                variant: 'outlined',
                onClick: handleTopicModalClick,
                placeholder:
                  filters.topics.length === 0 || topicModalOpen
                    ? t('Search new topic or author')
                    : '',
                disabled: filters.favorites,
                InputLabelProps: {
                  shrink: true,
                  disabled: true,
                },
                InputProps: {
                  sx: {
                    minWidth: '300px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c4c4c4',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#c4c4c4',
                      borderWidth: 1,
                    },
                    borderRadius: '8px',
                  },
                  ...(false || filters.topics.length === 0 || filters.favorites
                    ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search sx={{ marginLeft: '12px' }} />
                          </InputAdornment>
                        ),
                      }
                    : undefined),
                },
              }}
              autocompleteProps={{
                disableClearable: true,
                disableCloseOnSelect: true,
                disabled: filters.favorites,
                onBlur: () => {},
                freeSolo: true,
                clearIcon: null,
                limitTags: 6,

                onChange: (_, value) => {
                  handleUpdateSearch(value);
                },
                sx: {
                  minWidth: { xs: '100%', sm: '450px' },
                  '& .MuiAutocomplete-inputRoot': {
                    height: { xs: 'auto', md: '42px !important' },
                    padding: '0px !important',
                  },
                },
                renderTags: (topics, getTagProps) => {
                  const isLinkedIn = filters.insightTypes.includes(InsightType.Linkedin); // Check if LinkedIn is selected
                  const searchIcon = (
                    <Search
                      sx={{
                        color: 'rgba(0, 0, 0, 0.56)',
                      }}
                    />
                  );

                  return [
                    <div
                      key="search-icon"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '12px',
                      }}
                    >
                      {searchIcon}
                    </div>,
                    ...(!topicModalOpen
                      ? topics.map((option, index) => (
                          <Chip
                            sx={{
                              background: 'white',
                              height: '30px',
                              border: isLinkedIn
                                ? '1px solid rgba(0, 112, 210, 1)' // Blue border for LinkedIn
                                : '1px solid rgba(255, 0, 122, 1)', // Pink border for other types
                              padding: '0px 5px',
                              '& .MuiChip-label': {
                                color: isLinkedIn
                                  ? 'rgba(0, 112, 210, 1)'
                                  : 'rgba(255, 0, 122, 1)', // Blue text for LinkedIn
                                background: 'white',
                              },
                              '& .MuiChip-deleteIcon': {
                                color: isLinkedIn
                                  ? 'rgba(0, 112, 210, 1)'
                                  : 'rgba(255, 0, 122, 1)', // Blue delete icon for LinkedIn
                              },
                            }}
                            label={isLinkedIn ? option : `#${option}`} // Don't add # if LinkedIn is selected
                            {...getTagProps({ index })}
                            onDelete={() => {
                              handleUpdateSearch(topics.filter((t) => t !== option));
                            }}
                            key={index}
                          />
                        ))
                      : []),
                  ];
                },
              }}
            />
          </FormContainer>

          {/* Favorite button */}
          <Button
            data-testid="favorite-button"
            sx={{
              border: filters.favorites ? '1px solid #FF007A' : '1px solid #BBBBBB',
              color: filters.favorites ? '#FF007A' : 'rgba(0, 0, 0, 0.56)',
              backgroundColor: filters.favorites ? 'rgba(255, 0, 122, 0.08)' : 'white',
              minWidth: '42px',
              width: '42px',
              height: '42px',
              padding: '0px important!',
            }}
            variant={filters.favorites ? 'contained' : 'outlined'}
            onClick={() => onFiltersChange({ ...filters, favorites: !filters.favorites })}
          >
            <Favorite data-testid="favorite-filled-icon" sx={{ width: '20px' }} />
          </Button>
        </Stack>
      </Stack>

      {/* Second row: Publication Date button */}
      <Stack direction="row" justifyContent="flex-start" width="100%" mt={2}>
        <Button
          aria-controls="publication-date-menu"
          aria-haspopup="true"
          onClick={handleDateButtonClick}
          variant="outlined"
          endIcon={<FilterListIcon />} // Add the filter icon to the right
          sx={{
            borderColor: '#D3D3D3', // Light gray border color
            color: '#A9A9A9', // Light gray text color
            textTransform: 'none', // Keep the text normal case (not uppercase)
            '&:hover': {
              borderColor: '#A9A9A9', // Darker gray border on hover
            },
          }}
        >
          Publication Date
        </Button>
        <Menu
          id="publication-date-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => handleDateSelect(FrequencyFilters.Day)}
            selected={selectedFrequency === FrequencyFilters.Day}
          >
            {t('Last Day')}
          </MenuItem>
          <MenuItem
            onClick={() => handleDateSelect(FrequencyFilters.Week)}
            selected={selectedFrequency === FrequencyFilters.Week}
          >
            {t('Last Week')}
          </MenuItem>
          <MenuItem
            onClick={() => handleDateSelect(FrequencyFilters.Month)}
            selected={selectedFrequency === FrequencyFilters.Month}
          >
            {t('Last Month')}
          </MenuItem>
        </Menu>
      </Stack>

      <Stack direction="row" alignItems="center" mt={1}>
        <Box
          sx={{
            backgroundColor: '#eff2f5',
            borderRadius: '50%',
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            marginRight: '8px',
          }}
        >
          <StarIcon sx={{ color: '#30527c', fontSize: '18px' }} />
        </Box>
        <Typography variant="body2" color="#666666">
          {t(
            'Authors selected to help inspire you and interact with people relevant to you.',
          )}
        </Typography>
      </Stack>

      {/* Add the TopicSelectorModal here */}
      <TopicSelectorModal
        open={topicModalOpen}
        anchorElement={anchorModalElem!}
        onClose={() => setTopicModalOpen(false)}
        topics={filters.topics}
        onTopicsChange={handleUpdateSearch} // Update the topics when the modal closes
      />
    </Card>
  );
};
